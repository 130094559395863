<!--  -->
<template>
  <div class="file-folder-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '知识库',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->

    <div class="search">
      <van-field v-model="queryName">
        <template #button>
          <van-button
            @keydown.enter.native="handleSearch"
            size="small"
            type="primary"
            @click="handleSearch"
            >搜索</van-button
          >
        </template>
      </van-field>
    </div>
    <div class="view-tabs" v-if="!isSearch">
      <p class="view-name">
        <span v-for="(tab, i) in viewTabs" :key="i" @click="handleTab(tab, i)"
          >{{ tab }} <i v-if="i < viewTabs.length - 1">/ </i>
        </span>
      </p>
      <div class="opt-btn">
        <p class="new-folder" v-if="!isSearch" @click="handleAdd">新建目录</p>
        <p
          class="del-folder"
          v-if="fileList.length > 0"
          @click="handleDelFolder"
        >
          删除目录
        </p>
      </div>
    </div>
    <!-- 列表 -->
    <div class="list-box">
      <div class="file-folder" v-if="fileList.length > 0">
        <div class="item" v-for="(item, i) in fileList" :key="i">
          <span class="check-box">
            <van-checkbox
              v-if="item.isDir == 1"
              icon-size="0.24rem"
              v-model="item.checked"
              shape="square"
            ></van-checkbox>
          </span>

          <span class="icon">
            <img
              class="icon"
              style="width: 0.75rem; height: 0.75rem"
              fit="contain"
              :src="iconName(item)"
            />
          </span>
          <div class="right-box" @click="handleFile(item)">
            <div class="left-info">
              <p class="item-name">{{ item.fileName }}</p>
              <p class="item-info">
                <span class="user-name">{{ item.createUserName || "--" }}</span>
                <span class="time"
                  >{{ item.createTime || "2023-07-12" }} 更新</span
                >
              </p>
            </div>
            <van-icon
              v-if="item.isDir == 1"
              class="icon"
              name="arrow"
              size="0.2rem"
            />
          </div>
        </div>
      </div>
      <van-empty v-else />
    </div>
    <!-- 操作文件面板 -->
    <van-action-sheet class="action-sheet" v-model="isOptShow">
      <p
        class="action"
        @click="handleOpen()"
        v-if="moveForm && moveForm.isDir == 1"
      >
        打开
      </p>
      <p
        class="action"
        v-if="moveForm && moveForm.isDir == 0"
        @click="handleDown()"
      >
        下载
      </p>
      <p
        class="action"
        v-if="moveForm && moveForm.isDir == 0"
        @click="handleSee()"
      >
        预览
      </p>
      <p class="action" @click="handleDel()">删除</p>
      <p class="action" @click="handleMove()">移动</p>
    </van-action-sheet>
    <!-- 新建目录 -->
    <van-popup
      v-model="showAdd"
      position="bottom"
      class="popup-box"
      :lock-scroll="false"
      :style="{
        width: '100%',
        height: '50%',
        padding: '0.3rem',
        overflowY: 'auto',
        overflowX: 'hidden',
      }"
    >
      <van-form @failed="onFailed" class="main-wrapper" ref="addFolder">
        <van-field
          v-model="form.fileName"
          name="fileName"
          label="文件夹名称"
          required
          placeholder="请入文件夹名称"
          maxlength="20"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="switchSpaceType"
          name="spaceType"
          label="文件空间"
          required
          placeholder="请选择文件空间"
          maxlength="20"
          disabled
          :rules="[{ required: true, message: '' }]"
        >
        </van-field>
      </van-form>
      <div class="btn-box">
        <van-button
          round
          size="small"
          native-type="button"
          type="info"
          @click="sureAdd"
          style="width: 100%"
          >确认添加</van-button
        >
      </div>
    </van-popup>
    <!-- 移动 -->
    <van-popup
      v-model="showMove"
      position="bottom"
      class="popup-box"
      :lock-scroll="false"
      :style="{
        width: '100%',
        height: '80%',
        padding: '0.3rem',
        overflowY: 'auto',
        overflowX: 'hidden',
      }"
    >
      <div class="view-tabs" v-if="moveViews.length > 0">
        <span
          v-for="(tab, i) in moveViews"
          :key="i"
          @click="handleMoveTab(tab, i)"
          >{{ tab }} <i v-if="i < moveViews.length - 1">/ </i>
        </span>
      </div>
      <div class="list-box">
        <div class="file-folder" v-if="folderList.length > 0">
          <div
            class="item"
            v-for="(item, i) in folderList"
            :key="i"
            @click="handleMoveTo(item)"
          >
            <!-- <van-icon name="card" size="0.75rem" color="#008DF0" /> -->
            <img
              class="icon"
              style="width: 0.75rem; heihgt: 0.75rem"
              fit="contain"
              src="@/assets/images/fileManager/folder.png"
            />

            <span class="floder-name">{{ item.fileName }}</span>
          </div>
        </div>
        <van-empty v-else />
      </div>
      <div class="opt-btn-bottom">
        <el-button
          type="primary"
          plain
          style="
            width: 100%;
            height: 0.8rem;
            border-radius: 0.4rem;
            margin-top: 0.3rem;
            font-size: 0.26rem;
          "
          @click="onMoveSure"
          >添加到此</el-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapActions, mapState, mapMutations } from "vuex";
import { downLoadFile } from "@/api/fileManagerNew/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      queryName: "",
      queryParams: null,
      ischeckShow: false,
      isOptShow: false,
      curFile: null,
      viewTabCodes: [],
      showAdd: false,
      form: {
        fileName: "", //文件夹名称
        parentCode: "", //父级code，属主空间下可不传
        spaceCode: "", //空间所属编码（部门时必传）
        spaceType: 0, //空间类型0-公共空间1-部门空间2-个人空间
      },
      showMove: false,
      curMoveTo: null,
      moveViews: [],
      moveViewCodes: [],
    };
  },
  watch: {
    $route: {
      handler(val) {
        let query = val.query;
        this.queryParams = query;
        this.getDatas(query);
      },
      deep: true,
      immediate: true,
    },
    showMove(val) {
      this.moveViews = [];
      this.moveViewCodes = [];
      if (val) {
        let code = "";
        let path = "";
        let pathCode = "";
        let spaceCode = "";
        if (this.moveForm) {
          // if (this.queryParams.spaceType == 2) {
          //   code = "003";
          //   path = "个人空间";
          //   pathCode = "003";
          // } else if (this.queryParams.spaceType == 1) {
          //   code = "002";
          //   path = this.queryParams.path;
          //   pathCode = "002";
          //   spaceCode = this.queryParams.spaceCode;
          // } else {
          //   code = "001";
          //   path = "公共空间";
          //   pathCode = "001";
          // }
          if (this.moveForm.spaceType == 2) {
            code = "003";
            path = "个人空间";
            pathCode = "003";
          } else if (this.moveForm.spaceType == 1) {
            // console.log(this.moveForm.path);
            code = "002";
            if (this.moveForm.path.indexOf("/") > -1) {
              path = this.moveForm.path.split("/")[0];
            } else {
              path = this.moveForm.path;
            }

            // path = this.queryParams.activeMenu;
            pathCode = "002";
            spaceCode = this.moveForm.spaceCode;
          } else {
            code = "001";
            path = "公共空间";
            pathCode = "001";
          }
        }

        this.moveViews.push(path);
        this.moveViewCodes.push(pathCode);

        this.curMoveTo = {
          path: path,
          code: code,
          pathCode: pathCode,
          spaceCode: spaceCode,
        };
        this.getFolderLists({
          code: code,
          spaceCode: spaceCode,
        });
      } else {
        this.curMoveTo = null;
      }
    },
    queryName(val) {
      if (!val) {
        this.setIsSearch(false);
        this.getDatas(this.queryParams);
      }
    },
  },
  components: { TopWrapper },

  computed: {
    ...mapState("fileManagerNew", [
      "searchName",
      "fileList",
      "moveForm",
      "folderFiles",
      "folderList",
      "isSearch",
    ]),
    selectedFolders: {
      get() {
        let a = [];
        this.fileList.map((e) => {
          if (e.checked == true) {
            a.push(e);
          }
        });
        // console.log(a);

        return a;
      },
      set() {},
    },
    viewTabs: {
      get() {
        if (this.queryParams.path.indexOf("/") > 0) {
          this.viewTabCodes = this.queryParams.pathCode.split("/");
          return this.queryParams.path.split("/");
        } else {
          this.viewTabCodes = [this.queryParams.pathCode];
          return [this.queryParams.path];
        }
      },
      set() {},
    },
    switchSpaceType: {
      get() {
        let obj = {
          0: "公共空间",
          1: "部门空间",
          2: "个人空间",
        };
        return obj[this.queryParams.spaceType];
      },
      set() {},
    },
  },

  mounted() {
    this.queryName = "";
    this.setIsSearch(false);
  },

  methods: {
    ...mapMutations("fileManagerNew", [
      "setMoveForm",
      "setFolderFiles",
      "setIsSearch",
      "setFileList",
    ]),
    ...mapActions("fileManagerNew", [
      "searchFiles",
      "delFiles",
      "getFolderAndFileList",
      "addFolders",
      "getFolderLists",
      "removeFiles",
    ]),
    iconName(folder) {
      // console.log(folder);
      if (folder.isDir == 1) {
        return require("@/assets/images/fileManager/folder.png");
      } else {
        switch (folder.fileType) {
          case "png":
          case "jpg":
          case "jpeg":
          case "gif":
            return `${process.env.VUE_APP_KNOW_URL}/${folder.pathUrl}`;
          case "pdf":
            return require("@/assets/images/fileManager/pdf.png");
          case "ppt":
          case "pptx":
            return require("@/assets/images/fileManager/ppt.png");
          case "xls":
          case "xlsx":
            return require("@/assets/images/fileManager/xls.png");
          case "doc":
          case "docx":
          case "txt":
            return require("@/assets/images/fileManager/doc.png");
          case "rar":
          case "zip":
            return require("@/assets/images/fileManager/rar.png");
          default:
            return require("@/assets/images/fileManager/txt.png");
        }
      }
    },
    //点击文件或文件夹
    handleFile(item) {
      if (!this.selectedFolders.length) {
        let arr = [];
        arr = this.fileList.map((e) => {
          return {
            ...e,
            checked: item.code == e.code ? true : false,
          };
        });
        this.setFileList(arr);
        this.setMoveForm(item);
        this.isOptShow = true;
      } else {
        if (this.selectedFolders.length == 1) {
          //   this.setFileList(arr);
          this.setMoveForm(item);
          this.isOptShow = true;
        } else {
          return;
        }
      }
    },
    // 搜索
    handleSearch() {
      this.setIsSearch(true);
      this.searchFiles({ searchName: this.queryName });
    },
    //tab点击
    handleTab(tab, i) {
      const newPath = this.viewTabs
        .slice(0, i + 1)
        .toString()
        .replaceAll(",", "/");
      const newPathCode = this.viewTabCodes
        .slice(0, i + 1)
        .toString()
        .replaceAll(",", "/");
      const params = {
        code: this.viewTabCodes[i],
        activeMenu: this.queryParams.activeMenu,
        path: newPath,
        pathCode: newPathCode,
        fileName: tab,
        isDir: 1,
        spaceType: this.queryParams.spaceType,
        spaceCode: this.queryParams.spaceCode,
      };

      this.$router.replace({
        query: params,
      });
    },
    //新建
    handleAdd() {
      this.showAdd = true;
    },
    onFailed() {},
    //确认添加
    sureAdd() {
      this.$refs.addFolder.validate(["fileName", "spaceType"]).then((res) => {
        let { spaceType, code, spaceCode } = this.queryParams;
        const params = {
          ...this.form,
          spaceCode: spaceCode,
          parentCode: code,
          spaceType: Number(spaceType),
        };

        this.addFolders(params).then((res) => {
          if (res) {
            let { code, msg } = res;
            if (code == 200) {
              this.showAdd = false;
              Toast(msg);
              this.getDatas(this.queryParams);
            }
          }
        });
      });
    },
    //打开
    handleOpen() {
      this.queryName = "";
      this.setIsSearch(false);
      this.isOptShow = false;
      this.$router.replace({
        query: this.moveForm,
      });
    },
    //删除目录
    handleDelFolder() {
      if (this.selectedFolders.length > 0) {
        let arr = [];
        this.selectedFolders.map((e) => {
          arr.push({
            code: e.code,
          });
        });

        this.delFiles(arr).then((res) => {
          if (res) {
            let { code, msg } = res;
            if (code == 200) {
              Toast(msg);
              if (this.isSearch) {
                this.handleSearch();
              } else {
                this.getDatas(this.queryParams);
              }
            }
          }
        });
      } else {
        Toast("未选择删除文件目录");
      }
    },
    //下载
    handleDown() {
      if (window.plus) {
        // 创建下载任务
        const picurl = this.moveForm.pathUrl,
          that = this;
        //图片保存到手机后的路径
        let picname = `_downloads/${this.moveForm.fileName}`;
        var dtask = plus.downloader.createDownload(
          picurl,
          {
            filename: picname,
          },
          function (d, status) {
            // 下载完成
            if (status == 200) {
              plus.gallery.save(
                d.filename, //这个很关键记得要填d 返回的名字
                function () {
                  //保存到相册方法
                  that.show = false;
                  that.$toast("已保存到手机");
                },
                function (e) {
                  that.show = false;
                  that.$toast("保存失败，请重试！");
                }
              );
              // plus.runtime.openFile(d.filename, {}, function (e) {
              //   that.$toast("无法打开此文件");
              // });
            } else {
              that.$toast("Download failed: " + status);
            }
          }
        );
        dtask.start(); //开始下载
      } else {
        let types = ["txt", "TXT"];
        let bol = types.indexOf(this.moveForm.fileType) > -1;
        debugger
        if (!bol) {
          window.open(
            `${process.env.VUE_APP_KNOW_URL}/${this.moveForm.pathUrl}`,
            "_blank"
          );
        } else {
          downLoadFile([this.moveForm.code])
            .then((res) => {})
            .catch((e) => {
              // Toast(`下载${this.moveForm.fileType}文件,失败`);
              // 创建新的URL并指向File对象或者Blob对象的地址
              const blobURL = window.URL.createObjectURL(e);
              // 创建a标签，用于跳转至下载链接
              const tempLink = document.createElement("a");
              tempLink.style.display = "none";
              tempLink.href = blobURL;
              tempLink.setAttribute("download", decodeURI("下载文件"));
              // 兼容：某些浏览器不支持HTML5的download属性
              if (typeof tempLink.download === "undefined") {
                tempLink.setAttribute("target", "_blank");
              }
              // 挂载a标签
              document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(tempLink);
              // 释放blob URL地址
              window.URL.revokeObjectURL(blobURL);
            });
        }
      }
    },
    //预览
    handleSee() {
      if (this.moveForm) {
        this.$router.push({
          path: "/fileScan",
          query: {
            url: encodeURIComponent(
              `${process.env.VUE_APP_KNOW_URL}/${this.moveForm.pathUrl}`
            ),
            fileName: this.moveForm.fileName,
            FileType: this.moveForm.fileType,
          },
        });
      } else {
        Toast("未获取到预览文件");
      }
    },
    //删除
    handleDel() {
      this.delFiles([{ code: this.moveForm.code }]).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            this.isOptShow = false;
            Toast(msg);
            if (this.isSearch) {
              this.handleSearch();
            } else {
              this.getDatas(this.queryParams);
            }
          }
        }
      });
    },
    //移动
    handleMove() {
      this.showMove = true;
    },
    //移动点击
    handleMoveTab(tab, i) {
      this.curMoveTo = {
        code: this.moveViewCodes[i],
        path: this.moveViews[i],
        spaceCode: this.curMoveTo.spaceCode
          ? this.curMoveTo.spaceCode
          : this.queryParams.spaceCode,
      };
      this.moveViews = this.moveViews.slice(0, i + 1);
      this.moveViewCodes = this.moveViewCodes.slice(0, i + 1);

      this.getFolderLists({
        code: this.moveViewCodes[i],
        spaceCode: this.curMoveTo.spaceCode,
      });
    },
    handleMoveTo(item) {
      this.curMoveTo = item;
      this.moveViews = [];
      this.moveViewCodes = [];
      if (item.path.indexOf("/") > -1) {
        this.moveViews = item.path.split("/");
      }
      if (item.pathCode.indexOf("/") > -1) {
        this.moveViewCodes = item.pathCode.split("/");
      }
      this.getFolderLists({
        code: item.code,
        spaceCode: item.spaceCode,
      });
    },
    onMoveSure() {
      const params = {
        inCode: this.curMoveTo.code,
        outCode: this.moveForm.code,
      };
      this.removeFiles(params).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            this.searchName = "";
            Toast(msg);
            this.showMove = false;
            this.isOptShow = false;
            // this.getDatas(this.queryParams);
            if (this.isSearch) {
              this.handleSearch();
            } else {
              this.getDatas(this.queryParams);
            }
          }
        }
      });
    },
    //获取页面数据
    getDatas(item) {
      let params = { parentCode: "", spaceCode: "", spaceType: "" };
      params.parentCode = item.code;
      params.spaceType = item.spaceType;

      if (item.spaceCode == 0 || item.spaceCode == 1 || item.spaceCode == 2) {
        params.spaceCode = "";
      } else {
        params.spaceCode = item.spaceCode;
      }

      //获取文件和文件夹
      this.getFolderAndFileList(params);
    },
  },
};
</script>
<style lang="scss" scoped>
.file-folder-page {
  width: 100%;
  height: 100%;
  min-height: 6rem;
  background: #fafafa;
  position: relative;
  .view-tabs {
    width: 100%;
    background: #fafafa;
    padding: 0.3rem;
    .view-name {
      font-size: 0.26rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.37rem;
      .cur-name {
        color: #0094ee;
      }
    }
    .opt-btn {
      margin-top: 0.3rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      .new-folder {
        width: 1.28rem;
        height: 0.6rem;
        background: #0094ee;
        border-radius: 0.16rem;
        color: #fff;
        line-height: 0.6rem;
        text-align: center;
      }
      .del-folder {
        margin-left: 0.3rem;
        width: 1.28rem;
        height: 0.6rem;
        background: #e2f0f8;
        border-radius: 0.16rem;
        color: #0094ee;
        line-height: 0.6rem;
        text-align: center;
      }
    }
  }
  .list-box {
    width: 7.5rem;
    min-height: 1rem;
    background: #fff;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    .file-folder {
      width: 100%;

      .item {
        width: 100%;
        height: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 0.01rem solid #e5e5e5;
        padding-left: 0.3rem;
        padding-bottom: 0.3rem;
        padding: 0rem 0.3rem;
        .check-box {
          margin-right: 0.2rem;
        }
        .right-box {
          margin-left: 0.2rem;
          width: 5.5rem;
          // border-bottom: 0.01rem solid #e5e5e5;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .left-info {
            width: 100%;
            .icon {
              width: 0.2rem;
            }
            .item-name {
              font-size: 0.3rem;
              font-weight: 400;
              color: #333333;
              line-height: 0.42rem;
              overflow: hidden;
              text-overflow: ellipsis;
              transition: color 0.3s;
              white-space: nowrap;
              flex: 1;
              width: 5.3rem;
            }
            .item-info {
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-top: 0.08rem;
              .user-name {
                font-size: 0.26rem;
                font-weight: 400;
                color: #999999;
                line-height: 0.37rem;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: color 0.3s;
                white-space: nowrap;
                max-width: 3.2rem;
              }
              .time {
                margin-left: 0.3rem;
                font-size: 0.26rem;
                font-weight: 400;
                color: #999999;
                line-height: 0.37rem;
                width: 2rem;
              }
            }
          }
        }
      }
      .item:last-child {
        border: none;
      }
    }
  }
  .action-sheet {
    width: 100%;
    padding: 0.3rem;
    .action {
      width: 6.9rem;
      height: 0.8rem;

      border-radius: 0.4rem;
      margin-top: 0.3rem;
      line-height: 0.8rem;
      text-align: center;
    }
    .action:first-child {
      margin-top: 0rem;
      color: #fff;
      background: #0094ee;
    }
    .action:nth-child(2) {
      background: #e2f0f8;
      color: #0094ee;
    }
    .action:nth-child(3) {
      background: #fef0f0;
      color: #f56c6c;
    }
    .action:nth-child(4) {
      background: #f0f9eb;
      color: #67c23a;
    }
  }
  .opt-btn-bottom {
    // position: absolute;
    // bottom: 0.3rem;
    // right: 5%;
    // width: 90%;
    // overflow: hidden;
  }
  .btn-box {
    position: absolute;
    bottom: 0.3rem;
    width: 90%;
    right: 5%;
  }
}
</style>
